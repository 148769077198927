class TicketSubmitter {
    podio = null;
    miniTroubleshootingApp = null;
    troubleshootingApp = null;

    constructor(podio, miniTroubleshootingApp) {
        this.podio = podio;
        this.miniTroubleshootingApp = miniTroubleshootingApp;
    }

    setTroubleshootingApp() {
        return this.podio.getFullApp(this.miniTroubleshootingApp).then(app => {
            this.troubleshootingApp = app;
        });
    }

    createTicket(project, ticketData) {
        if (this.troubleshootingApp !== null) {
            return this.sendNewTicketToPodio(project, ticketData);
        } else {
            return this.setTroubleshootingApp()
                .then(() => this.sendNewTicketToPodio(project, ticketData));
        }
    }

    getStatusOptionId(status) {
        for (let field of this.troubleshootingApp.fields) {
            if (field.label === "Status") {
                for (const option of field.config.settings.options) {
                    if (option.text === status) {
                        return option.id;
                    }
                }
            }
        }
        return undefined;
    }

    getTimestamp() {
        let datetime = new Date();
        return `${datetime.getFullYear()}-${datetime.getMonth()+1}-${datetime.getDate()} ${datetime.getHours()}:${datetime.getMinutes()}:${datetime.getSeconds()}`;
    }
}

export default TicketSubmitter;