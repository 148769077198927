import { Component } from 'react';
import TicketScreen from './TicketScreen';
import "./WorkspaceList.css"

class WorkspaceList extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMessage: null,
            workspaces: []
        };
    }

    componentDidMount() {
        if (this.state.workspaces.length === 0) {
            this.props.podio.getSpaces(this.props.org_id).then(spaces => {
                this.setState({
                    errorMessage: null,
                    workspaces: spaces
                });
                this.props.stopLoader();
            }).catch(error => {
                this.setState({
                    errorMessage: error.message,
                    workspaces: []
                });
                this.props.stopLoader();
            });
        }
    }

    showTicketScreen(workspace){
        this.props.changePage(<TicketScreen podio={this.props.podio} workspace={workspace}/>);
    }

    render() {
        return (
            <div className="WorkspaceList">
                <div className="WorkspaceList-title">Select a Workspace</div>
                <div className="WorkspaceList-container">
                    {this.state.workspaces.map((workspace, index) => {
                        if (workspace.name.includes("Purelight")) {
                            return (
                                <div
                                    className="workspace"
                                    key={index}
                                    onClick={() => this.showTicketScreen(workspace)}
                                >
                                    {workspace.name}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
}
 
export default WorkspaceList;