import { Component } from "react";
import graphic from "./white-loader.png";
import "./SpinLoader.css";

class SpinLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            angle: 0
        };
        this.requestAnimationId = null;
    }

    componentDidMount() {
        this.requestAnimationId = requestAnimationFrame(this.spin.bind(this));
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.requestAnimationId);
    }

    spin() {
        if (this.state.angle === 360) {
            this.setState({
                angle: 0
            });
        } else {
            this.setState({
                angle: this.state.angle + this.props.speed
            });
        }
        this.requestAnimationId = requestAnimationFrame(this.spin.bind(this));
    }

    render() {
        let degrees = `rotate(${this.state.angle}deg)`;
        return (
            <div className="SpinLoader" style={{ width: this.props.size, height: this.props.size }}>
                <div className="SpinLoader-img" style={{
                    WebkitTransform: degrees,
                    MozTransformStyle: degrees,
                    msTransform: degrees,
                    OTransform: degrees,
                    transform: degrees
                }}>
                    <img src={graphic}/>
                </div>
            </div>
        );
    }
}

export default SpinLoader;