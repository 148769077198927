class TicketScreenWorkspaceRepository {
    podio = null;
    workspace = null;
    constructor(podio, workspace) {
        this.podio = podio;
        this.workspace = workspace;
    }

    getEssentialApps() {
        return this.podio.getWorkspaceApps(this.workspace).then(this.filterApps.bind(this))
    }

    filterApps(apps) {
        return Promise.resolve({
            projects: this.findProjectsApp(apps),
            troubleshooting: this.findTroubleshootingApp(apps)
        });
    }

    findProjectsApp(apps) {
        for (let app of apps) {
            if (app.config.name === "Projects" && app.status === "active") {
                return app;
            }
        }
        throw new Error("This workspace does not have a Projects app.");
    }

    findTroubleshootingApp(apps) {
        for (let app of apps) {
            if (app.config.name === "Troubleshooting" && app.status === "active") {
                return app;
            }
        }
        throw new Error("This workspace does not have a Troubleshooting app.");
    }
}
 
export default TicketScreenWorkspaceRepository;