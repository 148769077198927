import TicketSubmitter from "./TicketSubmitter";

class LegacyTicketSubmitter extends TicketSubmitter {

    sendNewTicketToPodio(project, ticketData) {
        let fields = {
            "title": this.findCustomerName(project),
            "date": this.getTimestamp(),
            "system": this.getStatusOptionId("New"),
            "issue-type": ticketData.type.id,
            "issue-details": ticketData.notes,
            "phone": this.findCustomerPhone(project),
            "email": this.findCustomerEmail(project),
            "location": this.findProjectAddress(project),
            "install-date": this.findInstallDate(project),
            "size": this.findSystemSize(project),
            "inverter": this.findInverter(project),
            "panel": this.findPanel(project)
        };
        return this.podio.createItem(this.troubleshootingApp, fields)
            .then(ticketId => this.addTicketToProject(project, ticketId));
    }

    findCustomerName(project) {
        return project.title;
    }

    findCustomerPhone(project) {
        for (let field of project.fields) {
            if (field.label.trim() === "⭐ Phone") {
                return field.values[0];
            }
        }
        return undefined;
    }

    findCustomerEmail(project) {
        for (let field of project.fields) {
            if (field.label.trim() === "⭐ Email") {
                return field.values[0];
            }
        }
        return undefined;
    }

    findProjectAddress(project) {
        for (let field of project.fields) {
            if (field.label === "Address") {
                return field.values[0];
            }
        }
        return undefined;
    }

    findInstallDate(project) {
        for (let field of project.fields) {
            if (field.label === "Install Date") {
                return field.values[0];
            }
        }
        return undefined;
    }

    findSystemSize(project) {
        for (let field of project.fields) {
            if (field.label === "System Size") {
                return field.values[0];
            }
        }
        return undefined;
    }

    findInverter(project) {
        for (let field of project.fields) {
            if (field.label === "Inverter") {
                if (field.values.length > 0) {
                    return this.findInverterId(field.values[0].value.text);
                } else {
                    break;
                }
            }
        }
        return undefined;
    }

    findInverterId(inverterName) {
        for (let field of this.troubleshootingApp.fields) {
            if (field.external_id === "inverter") {
                for (let option of field.config.settings.options) {
                    if (option.text === inverterName) {
                        return option.id;
                    }
                }
            }
        }
        throw new Error(`The Troubleshooting app does not have an option for the ${inverterName} inverter!`);
    }

    findPanel(project) {
        for (let field of project.fields) {
            if (field.label === "Panel Type") {
                if (field.values.length > 0) {
                    return this.findTroubleshootingPanelId(field.values[0].value.text);
                } else {
                    break;
                }
            }
        }
        return undefined;
    }

    findTroubleshootingPanelId(panelName) {
        for (let field of this.troubleshootingApp.fields) {
            if (field.external_id === "panel") {
                for (let option of field.config.settings.options) {
                    if (option.text === panelName) {
                        return option.id;
                    }
                }
            }
        }
        throw new Error(`The Troubleshooting app does not have an option for the ${panelName} panel!`);
    }

    addTicketToProject(project, ticketId) {
        return this.podio.addItemToRelationshipField(project, "support-tickets", ticketId);
    }

}

export default LegacyTicketSubmitter;