import TicketSubmitter from "./TicketSubmitter";

class RevampedTicketSubmitter extends TicketSubmitter {

    sendNewTicketToPodio(project, ticketData) {
        let fields = {
            "project": [project.item_id],
            "ticket-created-on": this.getTimestamp(),
            "status": this.getStatusOptionId("New"),
            "issue-type": ticketData.type.id,
            "issue-details": ticketData.notes,
        };
        return this.podio.createItem(this.troubleshootingApp, fields);
    }

}

export default RevampedTicketSubmitter;