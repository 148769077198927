import { Component } from "react";
import "./TicketNotesField.css";

class TicketNotesField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: null
        }
    }

    updateNotes(event) {
        this.setState({
            notes: event.target.value
        });
        this.props.onChange(event.target.value);
    }

    render() { 
        return (
            <div className="TicketForm-field TicketNotesField">
                <div className="TicketNotesField-label-container">
                    <label>Ticket Notes</label>
                </div>
                <textarea className="TicketNotesField-notes" onBlur={this.updateNotes.bind(this)} placeholder="Enter your notes..."></textarea>
            </div>
        );
    }
}
 
export default TicketNotesField;