import { Component } from "react";
import "./GlobalError.css";

class GlobalError extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        return (
            <div className="GlobalError">
                <div className="GlobalError-container">
                    <div className="GlobalError-close">
                        <button className="GlobalError-close-btn" onClick={this.props.onClose}>&times;</button>
                    </div>
                    <div className="GlobalError-message">
                        {this.props.error.message}
                    </div>
                </div>
            </div>
        );
    }
}
 
export default GlobalError;