import { Component } from "react";
import "./SubmitTicketSuccess.css";

class SubmitTicketSuccess extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoaded: false,
            isSuccess: false,
            isError: false,
            errorMessage: null
        };
    }

    componentDidMount() {
        this.props.stopLoader();
    }

    handleClick() {
        this.props.goToInitialPage();
    }

    render() {
        return(
            <div className="submit-success-container">
                <div className="submit-success-title">Success!</div>
                <div className="submit-success-message">Your troubleshooting ticket has been submitted to podio.
                     To fill out another form, click the button below.</div>
                <div className="sumbit-success-button-wrapper">
                    <button className="submit-success-button" onClick={this.handleClick.bind(this)}>Submit Another Form</button>
                </div>
            </div>
        );
    }
}
 
export default SubmitTicketSuccess;