import LegacyTicketSubmitter from "./LegacyTicketSubmitter";
import RevampedTicketSubmitter from "./RevampedTicketSubmitter";

const revampedTroubleshootingApps = [28343859,29484362];

class TicketSubmitterFactory {

    static getSubmitter(podio, troubleshootingApp){
        if(revampedTroubleshootingApps.includes(troubleshootingApp.app_id)){
            return new RevampedTicketSubmitter(podio, troubleshootingApp);
        } else {
            return new LegacyTicketSubmitter(podio, troubleshootingApp);
        }
    }

}

export default TicketSubmitterFactory;