import { Component } from "react";
import "./ProjectSearchResult.css";

class ProjectSearchResult extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        return (
            <div className="ProjectSearchField ProjectSearchResult-container">
                <button className="ProjectSearchField ProjectSearchResult-button" onClick={() => this.props.onSelect(this.props.project)}>{this.props.project.title}</button>
            </div>
        );
    }
}
 
export default ProjectSearchResult;
