import { Component } from "react";
import "./TicketTypeSelectionField.css";
import whiteArrow from "../utilities/whiteArrow.svg";

class TicketTypeSelectionField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            isOpen: false,
            isLoaded: false,
            isSuccess: false,
            isError: false,
            errorMessage: null,
            options: []
        };
    }

    componentDidMount() {
        if (this.state.options.length === 0) {
            this.props.podio.getCategoryFieldOptions(this.props.app, "issue-type")
                .then(options => {
                    this.setState({
                        isLoaded: true,
                        isSuccess: true,
                        isError: false,
                        errorMessage: null,
                        options: options.filter((option) => "active" === option.status)
                    });
                    this.props.onOptionsLoaded();
                }).catch(error => this.setState({
                    isLoaded: true,
                    isSuccess: false,
                    isError: true,
                    errorMessage: error.message,
                    options: []
                }));
        }
    }

    open() {
        this.setState({
            isOpen: true
        });
    }

    close() {
        this.setState({
            isOpen: false
        })
    }

    select(option) {
        this.setState({
            selected: option,
            isOpen: false
        });
        this.props.onChange(option);
    }

    render() { 
        return (
            <div className="TicketTypeSelectionField">
                {this.state.isLoaded && this.state.isSuccess ? (
                    <div className="TicketTypeSelectionField-container">
                        <div className={"TicketTypeSelectionField-value" + (this.state.isOpen ? " open" : "")} onClick={this.open.bind(this)}>
                            {
                                this.state.selected === null ? (
                                    <span className="TicketTypeSelectionField-placeholder">Select One</span>
                                ) : this.state.selected.text
                            }
                            <span className="TicketTypeSelectionField-arrow"><img src={whiteArrow} width="15px" height="15px"/></span>
                        </div>
                        <div className={"TicketTypeSelectionField-options" + (this.state.isOpen ? " open" : "")}>
                            <div className="TicketTypeSelectionField-options-container">
                                {this.state.options.map((option, index) => (
                                    <div className="TicketTypeSelectionField-option" key={index} onClick={() => this.select(option)}>{option.text}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : this.state.isLoaded && this.state.isError ? (
                    <div className="TicketTypeSelectionField-container">
                        <div className="TicketTypeSelectionField-error">{this.state.errorMessage}</div>
                    </div>
                ) : (
                    <div className="TicketTypeSelectionField-container">
                        <div className="TicketTypeSelectionField-loader">Loading Ticket Types...</div>
                    </div>
                )}
            </div>
        );
    }
}
 
export default TicketTypeSelectionField;