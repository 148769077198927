import { Component } from "react";
import ProjectSearchResults from "./ProjectSearchResults";
import ProjectSearchResult from "./ProjectSearchResult";
import "./ProjectSearchField.css";


class ProjectSearchField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchLoading: false,
            searchError: null,
            projectLoading: false,
            projectError: null,
            selectedProject: null,
            isFocused: false,
            results: []
        };
    }

    handleSearchInput(event) {
        let searchQuery = event.target.value.trim();
        if (searchQuery !== "") {
            this.setState({
                searchLoading: true
            });
            this.props.podio.searchForItems(this.props.app, searchQuery).then(results => {
                this.setState({
                    searchLoading: false,
                    results: results
                });
            }).catch(error => {
                console.log(error);
                this.setState({
                    searchLoading: false,
                    searchError: error.message
                });
            });
        } else {
            this.setState({
                searchLoading: false,
                searchError: null,
                results: []
            });
        }
    }

    focus() {
        this.setState({
            isFocused: true
        });
    }

    unFocus() {
        this.setState({
            isFocused: false
        });
    }

    isSearchSuccesful() {
        return (this.state.results.length > 0 && this.state.searchError === null)
    }

    shouldShowResults() {
        return this.state.isFocused && this.isSearchSuccesful();
    }

    isSelectingProject() {
        return this.state.projectLoading;
    }

    isSearchError() {
        return (this.state.searchError !== null)
    }

    handleSelect(searchResult) {
        this.setState({
            projectLoading: true
        });
        this.unFocus();
        this.props.podio.getFullItem(searchResult).then(item => {
            this.setState({
                projectLoading: false,
                projectError: null,
                selectedProject: item,
                results: []
            });
            this.props.onChange(item);
        }).catch(error => {
            console.log(error);
            this.setState({
                projectLoading: false,
                projectError: error.message
            });
        });
    }

    unsetProject() {
        this.setState({
            selectedProject: null
        });
    }
    
    render() { 
        return ( 
            <div className="TicketForm-field ProjectSearchField">
                {this.state.selectedProject !== null ? (
                    <div className='ProjectSearchField-selected-container'>
                        <span className='ProjectSearchField-selected-project'>{this.state.selectedProject.title}</span>
                        <button className='ProjectSearchField-change-button' onClick={this.unsetProject.bind(this)}>Change</button>
                    </div>
                ) : this.isSelectingProject() ? (
                    <div className='ProjectSearchField-selected-container'>
                        <span className='ProjectSearchField-selected-project'>Loading...</span>
                        <button className='ProjectSearchField-change-button' disabled onClick={this.unsetProject.bind(this)}>Change</button>
                    </div>
                ) : (
                    <input 
                        className={"ProjectSearchField-input" + (this.shouldShowResults() ? " loaded" : "")}
                        type="text"
                        onChange={this.handleSearchInput.bind(this)}
                        onFocus={this.focus.bind(this)}
                        placeholder="Search..."
                    />
                )}
                {this.shouldShowResults() ? (
                    <ProjectSearchResults show={this.state.isFocused}>
                        {this.state.results.length > 0 ? this.state.results.map((result, index) => (
                            <ProjectSearchResult
                                key={index}
                                project={result}
                                onSelect={this.handleSelect.bind(this)}
                            />
                        )) : (
                            <div className="ProjectSearch-error-message">No items could be found that match your search...</div>
                        )}
                    </ProjectSearchResults>
                ) : this.isSearchError() && (
                    <div className="ProjectSearch-error-message">{this.state.error}</div>
                )}
            </div>
        );
    }
}
 
export default ProjectSearchField;