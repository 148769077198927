import { Component } from "react";
import TicketTypeSelectionField from "./TicketTypeSelectionField";
import "./TicketTypeField.css";

class TicketTypeField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            option: null
        }
    }

    updateOption(option) {
        this.setState({
            option: option
        });
        this.props.onChange(option);
    }

    render() { 
        return (
            <div className="TicketForm-field TicketTypeField">
                <div className="TicketTypeField-label-container">
                    <label>Ticket Type</label>
                </div>
                <TicketTypeSelectionField 
                    podio={this.props.podio}
                    app={this.props.app}
                    onChange={this.updateOption.bind(this)}
                    onOptionsLoaded={this.props.onLoad}
                />
            </div>
        );
    }
}
 
export default TicketTypeField;