import { Component } from "react";
import "./ProjectSearchResults.css";

class ProjectSearchResults extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        return (
            <div className={"ProjectSearchResults" + (this.props.show ? "" : " hidden")}>
                <div className="ProjectSearchResults-container search-results">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
 
export default ProjectSearchResults;