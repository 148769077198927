import {api as PodioJs} from 'podio-js';


class PodioApiClient {
    podio = null;

    constructor(podio) {
        if (podio instanceof PodioJs) {
            this.podio = podio;
        } else {
            throw new Error("The passed argument is not an instance of the PodioJS class.");
        }
    }

    getSpaces(orgId) {
        return this.podio.request("GET", `/space/org/${orgId}`).then(response => {
            if (response.length !== undefined) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(new Error("Podio returned an unexpected payload. We were expecting an array, instead a " + typeof response + " was returned."));
            }
        }).catch(error => {
            console.log(`Podio returned an error when getting the workspaces in organization ${orgId}`);
            console.log(error);
            return Promise.reject(new Error("Podio had trouble finding the workspaces for your organization. Please let Casey know about this issue."));
        });
    }

    getFullApp(miniApp) {
        return this.podio.request("GET", `/app/${miniApp.app_id}`)
            .catch(error => {
                console.log(`Podio returned an error when getting the ${miniApp.config.name} app.`);
                console.log(error);
                return Promise.reject(new Error(`Podio had trouble finding the ${miniApp.config.name} app. Please let Casey know about this issue.`));
            }).then(response => {
                if (response.app_id !== undefined) {
                    return Promise.resolve(response);
                }
                return Promise.reject(new Error("Podio returned an object that is not an app."));
            });
    }

    getAppWithLabel(workspace, label) {
        return this.podio.request("GET", `/app/space/${workspace.space_id}/`).catch(error => {
            console.log(`Podio returned an error when getting the apps in the ${workspace.name} workspace.`);
            console.log(error);
            return Promise.reject(new Error(`Podio had trouble finding the apps in the ${workspace.name} workspace. Please let Casey know about this issue.`));
        }).then(response => {
            for (let app of response) {
                if (app.config.name === label && app.status === "active") {
                    return Promise.resolve(app);
                }
            }
            return Promise.reject(new Error(`The ${workspace.name} workspace is missing a ${label} application.`));
        });
    }

    isProjectsApp(app) {
        return app.config.name === "Projects" && app.status === "active";
    }

    isTroubleShootingApp(app) {
        return app.config.name === "Troubleshooting" && app.status === "active";
    }

    getCategoryFieldOptions(app, field) {
        return this.podio.request("GET", `/app/${app.app_id}/field/${field}`)
            .catch(error => {
                console.log(`Podio returned an error when getting the options for the ${field} field in the ${app.config.name} app.`);
                console.log(error);
                return Promise.reject(new Error(`Podio had trouble finding the available options for the ${field} field in the ${app.config.name} app. Please let Casey know about this issue.`));
            })
            .then(response => Promise.resolve(response.config.settings.options));
    }

    createItem(app, fields) {
        return this.podio.request("POST", `/item/app/${app.app_id}`, { fields: fields })
            .catch(error => {
                console.log(`Podio returned an error when creating an item in the ${app.config.name} app.`);
                console.log(error);
                return Promise.reject(new Error(`Podio had trouble creating an item in the ${app.config.name} app. Please let Casey know about this issue.`));
            }).then(response => {
                return Promise.resolve(response.item_id);
            })
    }

    addItemToRelationshipField(parent, field, childId) {
        return this.podio.request("GET", `/item/${parent.item_id}/value/${field}/v2`)
            .then(response => {
                let values = [];
                for (let ticket of response.values) {
                    values.push(ticket.item_id);
                }
                values.push(childId);
                return this.podio.request("PUT", `/item/${parent.item_id}/value/${field}`, values);
            });
    }

    searchForItems(app, query) {
        return this.podio.request("POST", `/search/app/${app.app_id}`, { query, limit: 10, ref_type: "item" })
            .then(response => {
                if (response.length !== undefined) {
                    return Promise.resolve(response);
                }
                return Promise.reject(new Error(`Podio returned an unexpected response when searching the ${app.config.name} app.`));
            }).catch(error => {
                console.log(`Podio returned an error when searching for an item in the ${app.config.name} app.`);
                console.log(error);
                return Promise.reject(new Error(`Podio had trouble searching the ${app.config.name} app. Please let Casey know about this issue.`));
            });
    }

    getFullItem(miniItem) {
        return this.podio.request("GET", `/item/${miniItem.id}`)
            .catch(error => {
                console.log(`Podio returned an error when getting the ${miniItem.title} item.`);
                console.log(error);
                return Promise.reject(new Error(`Podio had trouble finding the ${miniItem.title} item. Please let Casey know about this issue.`));
            }).then(response => Promise.resolve(response));
    }

    getWorkspaceApps(workspace) {
        return this.podio.request("GET", `/app/space/${workspace.space_id}/`).catch(error => {
            console.log(`Podio returned an error when getting the apps in the ${workspace.name} workspace.`);
            console.log(error);
            return Promise.reject(new Error(`Podio returned an error when getting the apps in the ${workspace.name} workspace. Please let Casey know about this issue.`))
        });
    }
}

export default PodioApiClient;