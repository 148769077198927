import { api as PodioJs } from "podio-js";
import { Component } from 'react';
import "./App.css";
import PodioApiClient from "./podio/utilities/PodioApiClient";
import Navigator from "./podio/Navigator";
import WorkspaceList from "./podio/WorkspaceList";
import podioLogo from "./podio/white-transparent.png";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuthenticated: false,
			isError: false,
			errorMessage: null,
			user: null
		};
		this.podio = new PodioJs({
			authType: "client",
			clientId: "ticket-submission"
		});
		this.authUrl = this.podio.getAuthorizationURL(window.location.href);
	}

	componentDidMount() {
		this.podio.isAuthenticated().then(this.setProfile.bind(this)).catch(() => {
			this.setState({ isAuthenticated: false });
		});
	}

	setProfile() {
		this.podio.request("GET", "/user/profile/").then(response => {
			this.setState({
				isAuthenticated: true,
				isError: false,
				errorMessage: null,
				user: response
			});
		}).catch(error => {
			console.log(error);
			this.setState({
				isAuthenticated: false,
				isError: true,
				errorMessage: "There was an error retrieving your user information from Podio.",
				user: null
			});
		});
	}

	authenticate() {
		this.podio.isAuthenticated().catch(() => {
			window.location = this.authUrl;
		});
	}

	render() {
		return (
			<div className="App">
				{this.state.isAuthenticated && !this.state.isError ? (
					<div className="home-body">
						<Navigator podio={new PodioApiClient(this.podio)} initialPage={<WorkspaceList org_id={1538669}/>}/>
					</div>
				) : this.state.isAuthenticated && this.state.isError ? (
					<div className="home-error-message">{this.state.errorMessage}</div>
				) : (
					<div className="home-body">
						<div>
							<div className="home-image-wrapper"><img src={podioLogo} width="200px" height="auto"/></div>
							<div className="home-button-wrapper"><button className="home-button" onClick={this.authenticate.bind(this)}>Sign in with Podio</button></div>
						</div>
					</div>
				)}
				<div className="home-footer">
					<div className="copyright">Created by PureLight Power Software Team &copy; {(new Date()).getFullYear()}</div>
				</div>
			</div>
		);
	}
}

export default App;
