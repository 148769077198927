import TicketSubmitterFactory from "./utilities/TicketSubmitterFactory";
import { Component } from "react";
import ProjectSearchField from "./fields/ProjectSearchField";
import TicketTypeField from "./fields/TicketTypeField";
import TicketNotesField from "./fields/TicketNotesField";
import "./TicketForm.css";

class TicketForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: null,
            ticketType: null,
            ticketNotes: null
        };
        this.ticketSubmitter = TicketSubmitterFactory.getSubmitter(props.podio, props.troubleshootingApp);
    }

    setProject(project) {
        this.setState({
            project: project
        });
    }

    setTicketType(ticketType) {
        this.setState({
            ticketType: ticketType
        });
    }

    setTicketNotes(ticketNotes) {
        this.setState({
            ticketNotes: ticketNotes
        });
    }

    validate() {
        if (this.state.project === null) {
            return new Error("You must select a project before submitting your ticket!");
        }
        if (this.state.ticketType === null) {
            return new Error("You must select a ticket type before submitting your ticket!");
        }
        if (this.state.ticketNotes === null || this.state.ticketNotes.trim() === "") {
            return new Error("You must enter ticket notes before submitting your ticket!");
        }
        return null;
    }

    submit() {
        this.props.startLoader();
        let error = this.validate();
        if (error === null) {
            this.ticketSubmitter.createTicket(this.state.project, {
                type: this.state.ticketType,
                notes: this.state.ticketNotes
            }).then(() => {
                this.props.onSubmit(null);
            }).catch(error => {
                this.props.onSubmit(error);
            });
        } else {
            this.props.onSubmit(error);
        }
    }
    
    render() {
        return (
            <div className="TicketForm">
                <div className="TicketForm-container">
                    <ProjectSearchField
                        podio={this.props.podio}
                        app={this.props.projectsApp}
                        onChange={this.setProject.bind(this)}
                    />
                    <TicketTypeField
                        podio={this.props.podio}
                        app={this.props.troubleshootingApp}
                        onChange={this.setTicketType.bind(this)}
                        onLoad={this.props.stopLoader}
                    />
                    <TicketNotesField onChange={this.setTicketNotes.bind(this)}/>
                    <button className="TicketForm-button" onClick={this.submit.bind(this)}>Submit</button>
                </div>
            </div>
        );
    }
}
 
export default TicketForm;