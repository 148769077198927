import TicketForm from "./TicketForm";
import TicketScreenWorkspaceRepository from "./utilities/TicketScreenWorkspaceRepository";
import SubmitTicketSuccess from "./SubmitTicketSuccess.js";
import { Component } from "react";

class TicketScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            projectsApp: null,
            troubleshootingApp: null
        };
        this.repository = new TicketScreenWorkspaceRepository(props.podio, props.workspace);
    }

    componentDidMount() {
        this.repository.getEssentialApps().then(({ projects, troubleshooting }) => {
            this.setState({
                error: null,
                projectsApp: projects,
                troubleshootingApp: troubleshooting
            });
        }).catch(error => {
            this.setState({
                error: error.message
            });
        });
    }

    isError() {
        return this.state.error !== null;
    }

    essentialAppsLoaded() {
        return (this.state.projectsApp !== null && this.state.troubleshootingApp !== null && this.state.error === null);
    }

    showSuccessPage() {
        this.props.changePage(<SubmitTicketSuccess/>);
    }

    handleSubmission(error) {
        if (error === null) {
            this.showSuccessPage();
        } else {
            this.props.showError(error);
            this.props.stopLoader();
        }
    }

    render() {
        return (
            <div className="TicketScreen">
                {(this.essentialAppsLoaded()) ? (
                    <TicketForm
                        podio={this.props.podio}
                        projectsApp={this.state.projectsApp}
                        troubleshootingApp={this.state.troubleshootingApp}
                        startLoader={this.props.startLoader}
                        stopLoader={this.props.stopLoader}
                        onSubmit={this.handleSubmission.bind(this)}
                    />
                ) : this.isError() && (<div className="error-message">{this.state.error}</div>)}
            </div>
        );
    }
}
 
export default TicketScreen;